.swiper {
  /* display: flex !important;
  ;;width: 500px !important;
  height: auto !important; */
  /* background-color: yellow !important; */
  /* //padding: 20px; */
  height: auto !important;
  /* background-color: white !important; */
  padding-bottom: 20px !important;
  
}

.swiper-slide {
  /* display: flex !important;
  
  font-size: 18px;
  background: white; */
  /*background-color: white !important;*/
  height: max-content !important;
  width: 100% !important; 
  
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px !important;
}